<template>
    <div style="height: 100%">
        <tabbed-page
            :tabList="tabList"
            @changeTabs="handlerChangeTabs"
        >
        </tabbed-page>
        <keep-alive :include="['EntryStatisticsList', 'EntryRecordList']">
            <component
                :is="currentComp"
                :query="query"
                @change="handleChangeComp"
                @setPageInitTabs="setPageInitTabs"
            ></component>
        </keep-alive>
    </div>
</template>

<script>
import TabbedPage from './Sub/TabbedPage/index.vue'
import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
export default {
    name: "ParentAccessScrollWrapper",
    components: {
        TabbedPage,
        EntryRecordList: () => import(/* webpackChunkName:'EntryRecordList' */"@/components/scrollWrapper/ParentAccess/EntryRecordList"),
        EntryStatisticsList: () => import(/* webpackChunkName:'EntryStatisticsList' */"@/components/scrollWrapper/ParentAccess/EntryStatisticsList"),
    },
    data() {
        return {
            currentComp: "EntryRecordList",
            tabList: [
                {
                    id: 0,
                    title: "出入记录",
                    com: "EntryRecordList",
                },
                {
                    id: 1,
                    title: "出入统计",
                    com: "EntryStatisticsList",
                },
            ],
            query: {},
            formData: {},
            type: 'list',
        };
    },
    activated() {
        this.init();
        this.setGlobalPageType(this.type)
    },
    created() {
        this.init();
    },
    methods: {
        init () {
            this.tabList = tabPageHasAuth(this.tabList);
            this.handlerChangeTabs({
                id: 0,
                title: "出入记录",
                com: "EntryRecordList",
            })
            this.currentComp = this.tabList.map(item => item.com)[0];
        },
        handlerChangeTabs(data) {
            this.tabList.forEach((item, index) => {
                if (item.id === data.id) {
                    this.$set(item, 'sel', true)
                    this.currentComp = item.com
                } else {
                    this.$set(item, 'sel', false)
                }
            })
            console.log('this.tabList',this.tabList);
            
        },
        handlerChangeTab(e) {
            this.currentComp = e.compName;
        },
        handleChangeComp(e) {
            this.currentComp = e.compName
            this.query = e.query
            let type = 'list'
            this.setGlobalPageType(type)
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
        setPageInitTabs(){
            this.type = 'list'
            this.currentComp = "EntryRecordList"
            this.$eventDispatch('setGlobalPageType', 'list')

        }
    },
};
</script>

<style lang="scss" scoped>
.tabs-list {
    border-radius: 4px 0 0 4px;
    display: flex;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background-color: #fff;
    padding-left: 30px;

    .tabs-item {
        position: relative;
        text-align: center;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
        margin-right: 48px;
    }

    .tabs-item.current {
        position: relative;
        color: #3c7fff ;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #3C7FFF;
            border-radius: 1px;
        }
    }
}
</style>
